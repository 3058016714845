import ASCIIFolder from 'src/lib/ascii-folder.js';

class HighlightingService {
  highlight(text, highlights) {
    let highlightedText = text;

    highlights.forEach(hl => {
      if (!hl) { return; }

      highlightedText = this.#singleHighlight(highlightedText, hl);
    });

    return highlightedText;
  }

  #singleHighlight(text, highlight) {
    const strongTagsRegex = /(<strong>.*?<\/strong>)/g;
    const splitText       = text.split(strongTagsRegex);
    let   highlightedText = '';

    splitText.forEach((section) => {
      if (this.#isHighlighted(section)) {
        highlightedText += section;
      }
      else {
        highlightedText += this.#highlightSubstring(section, highlight);
      }
    });

    return highlightedText;
  }

  #highlightSubstring(text, highlight) {
    const windowSize = highlight.length;

    let highlightedText = '';
    let windowText;

    for (let i = 0; i < text.length; i++) {
      windowText = text.slice(i, i + windowSize);

      if (this.#asciiFold(windowText).toLowerCase() === this.#asciiFold(highlight).toLowerCase()) {
        highlightedText += `<strong>${windowText}</strong>`;
        i += windowSize - 1;
      }
      else {
        highlightedText += windowText[0];
      }
    }

    return highlightedText;
  }

  #asciiFold(text) {
    return ASCIIFolder.foldReplacing(text);
  }

  #isHighlighted(text) {
    return /<strong>.*?<\/strong>/.test(text);
  }
}

export default new HighlightingService;
