<template>
  <validation-provider :rules="validationRules" v-slot="{ errors, failed }" :mode="passiveAggressive">
    <label :for="id" v-if="label">{{ label }} <span class="inline-help required" v-if="required">*</span></label>

    <textarea ref="textarea" :id="id" :name="name" :class="['form-control', { 'has-error': failed }]" :rows="rows" :placeholder="placeholder" :maxlength="charLimit" :value="value" @focus="resize" @input="onInput"></textarea>

    <div v-if="charLimit" class="dark-gray-text top-5">
      Limited to {{ charLimit }} characters
    </div>

    <div v-if="errors.length">
      <slot name="errors" :errors="errors[0]">
        <div class="error-text top-5">
          {{ errors[0] }}
        </div>
      </slot>
    </div>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import interactionModes from '../mixins/interaction-modes';

export default {
  name: 'TextareaInputVertical',

  components: {
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    value: {
      type: String,
      default: ''
    },

    label: {
      type: String,
      required: false
    },

    id: {
      type: String,
      required: false
    },

    name: {
      type: String,
      required: false
    },

    rows: {
      type: Number,
      default: 5
    },

    placeholder: {
      type: String,
      default: null
    },

    charLimit: {
      type: Number,
      required: false
    },

    autoresize: {
      type: Boolean,
      default: false
    },

    rules: {
      type: String,
      default: ''
    }
  },

  computed: {
    validationRules() {
      if (this.charLimit) {
        return `${this.rules}|max:${this.charLimit}`;
      }
      else {
        return this.rules;
      }
    },

    required() {
      return this.rules?.includes('required');
    }
  },

  methods: {
    onInput(event) {
      if (this.autoresize) { this.resize(); }
      this.$emit('input', event.target.value);
    },

    resize() {
      if (!this.autoresize) { return; }

      this.$refs.textarea.style.height = 'auto';
      // NOTE: scrollHeight does not include borders, so add 2px
      this.$refs.textarea.style.height = `${this.$refs.textarea.scrollHeight + 2}px`;
    }
  }
};
</script>
