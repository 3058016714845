<template>
  <validation-provider :rules="rules" v-slot="{ errors }" :mode="passiveAggressive">
    <label :for="`raw-input-${uniqueId}`" v-if="hasLabel">{{ label }} <span class="red-text" v-if="required && !disabled">*<span class="error-text" v-if="showRequiredLabel"> Required</span></span></label>

    <slot name="description"></slot>

    <div v-show="editMode" class="keyword-search-container typeahead-container">
      <slot name="prefix-symbol"></slot>
      <input
        ref="rawInput"
        type="text"
        :data-testid="id"
        :id="`raw-input-${uniqueId}`"
        :class="['form-control keyword-search-input', { 'has-error': errors.length, 'disabled': disabled }, additionalInputClasses]"
        :placeholder="placeholder"
        :disabled="disabled"
        autocomplete="nope"
        @input="forceSelect ? () => {} : onInput($event.target.value)"
        @keydown.enter.prevent="allowEnterKeydown ? onEnterKeydown($event.target.value) : () => {}"
        @blur="resetActiveIndex"
        @keydown="forwardEvent"
        @keyup="forwardEvent"
        v-model="rawInputModel">

      <input
        ref="asciiFoldedInput"
        :id="`folded-input-${uniqueId}`"
        :name="uniqueName"
        class="hidden"
        autocomplete="nope"
        v-model="inputModel">

      <svg-icon v-if="!hideIcon" name="search" class="base-icon keyword-search-icon"></svg-icon>
    </div>

    <div v-if="!editMode">
      {{ value }}
    </div>

    <div class="error-text top-5" v-if="displayError && errors.length">
      {{ errors[0] }}
    </div>

    <div v-if="asyncFunction">
      <typeahead
        ref="typeahead"
        v-model="model"
        :target="`#raw-input-${uniqueId}`"
        :async-function="getOptions"
        :item-key="optionLabelKey"
        :force-select="forceSelect"
        :force-clear="forceSelect"
        :limit="limit">
        <template #item="{items, select, activeIndex }">
          <li v-for="(item, index) in items" :key="index" :class="{'active': index === activeIndex}">
            <a role="button" @click="select(item)">
              <span v-html="highlight(optionLabelKey ? item[optionLabelKey] : item)"></span>
            </a>
          </li>
        </template>
      </typeahead>
    </div>

    <div v-else>
      <typeahead
        ref="typeahead"
        v-model="model"
        :target="`#folded-input-${uniqueId}`"
        :data="sanitizedOptions"
        item-key="asciiFoldedLabel"
        :force-select="forceSelect"
        :force-clear="forceSelect"
        :limit="limit">
        <template #item="{items, select, activeIndex }">
          <li v-for="(item, index) in items" :key="index" :class="{'active': index === activeIndex}">
            <a role="button" @click="select(item)">
              <span v-html="highlight(optionLabelKey ? item[optionLabelKey] : item.label)"></span>
            </a>
          </li>
        </template>
      </typeahead>
    </div>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import { now, some, unescape, map } from 'lodash';
import highlightingService from 'src/services/highlighting-service.js';
import ASCIIFolder from 'src/lib/ascii-folder.js';

export default {
  name: 'TypeaheadVertical',

  components: {
    SvgIcon,
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    label: {
      type: String,
      required: false
    },

    id: {
      type: String,
      required: true
    },

    inputName: {
      type: String,
      required: false
    },

    additionalInputClasses: {
      type: [String, Array],
      default: () => ''
    },

    placeholder: {
      type: String,
      required: false
    },

    value: {
      type: [String, Number, Object],
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    editMode: {
      type: Boolean,
      default: true
    },

    rules: {
      type: [String, Object],
      default: null
    },

    options: {
      type: Array,
      required: true
    },

    optionLabelKey: {
      type: String,
      required: false
    },

    optionValueKey: {
      type: String,
      required: false
    },

    forceSelect: {
      type: Boolean,
      default: true
    },

    showRequiredLabel: {
      type: Boolean,
      required: false
    },

    initialValue: {
      type: String,
      required: false
    },

    displayError: {
      type: Boolean,
      default: true
    },

    hideIcon: {
      type: Boolean,
      default: false
    },

    allowEnterKeydown: {
      type: Boolean,
      default: false
    },

    limit: {
      type: Number,
      default: 10
    },

    asyncFunction: {
      type: Function,
      required: false
    },

    excludedOptions: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      model: '',
      rawInputModel: '',
      inputModel: ''
    };
  },

  computed: {
    hasLabel() {
      return this.label && this.label.length;
    },

    uniqueId() {
      // NOTE: this is an attempt at breaking Chrome's autocomplete nonsense
      return `${this.id}-${now()}`;
    },

    uniqueName() {
      return this.inputName ? `${this.inputName}${now()}` : null;
    },

    required() {
      return this.rules?.includes('required');
    },

    sanitizedOptions() {
      let filteredOptions = this.options;

      if (this.excludedOptions.length) {
        filteredOptions = this.options.filter(option => {
          return this.optionValueKey ? !this.excludedOptions.includes(option[this.optionValueKey]) : !this.excludedOptions.includes(option);
        });
      }

      return filteredOptions.map((option) => {
        if (this.optionLabelKey) {
          option[this.optionLabelKey] = this.$sanitize(option[this.optionLabelKey], { textFilter: text => unescape(text) });
          option.asciiFoldedLabel = this.asciiFold(option[this.optionLabelKey]);

          return option;
        }
        else {
          const sanitizedOption = this.$sanitize(option, { textFilter: text => unescape(text) });

          return {
            label: sanitizedOption,
            asciiFoldedLabel: this.asciiFold(sanitizedOption)
          };
        }
      });
    }
  },

  watch: {
    model(value) {
      if (typeof value === 'undefined') { return; }

      this.onInput(value);
      this.onEnterKeydown(value);

      this.$nextTick(() => {
        this.$refs.typeahead.open = false;
      });
    },

    initialValue(value) {
      this.rawInputModel = value;
    },

    inputModel(value) {
      if (typeof value === 'undefined') { return; }

      if (value == '') {
        this.resetActiveIndex();
      }

      if (!value || this.asyncFunction) { return; }

      this.checkForSubstringMatches(value);
    },

    rawInputModel(value) {
      this.triggerHiddenInputEvent(this.asciiFold(value));
    }
  },

  beforeMount() {
    this.rawInputModel = this.initialValue;
    this.model         = this.value;
  },

  methods: {
    onInput(value) {
      if (typeof value === 'undefined') { return; }

      this.rawInputModel = (this.optionLabelKey && value?.[this.optionLabelKey]) || value?.label || value;
      this.emitEvent('input', value);
    },

    onEnterKeydown(value) {
      if (typeof value === 'undefined') { return; }

      this.rawInputModel = (this.optionLabelKey && value?.[this.optionLabelKey]) || value?.label || value;
      this.emitEvent('enterKeydown', value);
    },

    emitEvent(eventType, value) {
      if (this.optionLabelKey && this.optionValueKey) {
        this.$emit(eventType, value?.[this.optionValueKey]);
      }
      else if (this.optionLabelKey) {
        this.$emit(eventType, value);
      }
      else {
        this.$emit(eventType, value?.label || value);
      }
    },

    highlight(text) {
      return highlightingService.highlight(text, this.rawInputModel.split(' '));
    },

    asciiFold(text) {
      return ASCIIFolder.foldReplacing(text);
    },

    checkForSubstringMatches(value) {
      const substringMatchesPresent = some(this.sanitizedOptions, option => option.asciiFoldedLabel.toLowerCase().includes(value.toLowerCase()));

      this.$emit('substring-matches-present', substringMatchesPresent);
    },

    getOptions(searchValue, done) {
      const vueInstance = this;

      return vueInstance.asyncFunction(searchValue, this.limit).then(response => {
        let filteredOptions = response;

        if (vueInstance.excludedOptions.length) {
          const excludedValues = vueInstance.optionLabelKey ? map(vueInstance.excludedOptions, vueInstance.optionLabelKey) : vueInstance.excludedOptions;
          filteredOptions = response.filter(option => !excludedValues.includes(vueInstance.optionLabelKey ? option[vueInstance.optionLabelKey] : option));
        }

        const sanitizedOptions = filteredOptions.map(option => {
          if (vueInstance.optionLabelKey) {
            option[vueInstance.optionLabelKey] = vueInstance.$sanitize(option[vueInstance.optionLabelKey], { textFilter: text => unescape(text) });
          }

          return vueInstance.optionLabelKey ? option : vueInstance.$sanitize(option, { textFilter: text => unescape(text) });
        });

        done(sanitizedOptions);

        const substringMatchesPresent = !!response.length;
        vueInstance.$emit('substring-matches-present', substringMatchesPresent);
      });
    },

    forwardEvent(event) {
      const hiddenInput = this.$refs.asciiFoldedInput;
      const newEvent    = new KeyboardEvent(event.type, event);

      hiddenInput.dispatchEvent(newEvent);
    },

    triggerHiddenInputEvent(value) {
      const event = new Event('input', { bubbles: true });

      this.$refs.asciiFoldedInput.value = value;
      this.$refs.asciiFoldedInput.dispatchEvent(event);
    },

    resetActiveIndex() {
      this.$refs.typeahead._data.activeIndex = 0;
    },

    reset() {
      this.model         = '';
      this.inputModel    = '';
      this.rawInputModel = '';
    }
  }
};
</script>
